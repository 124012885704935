import React from 'react'
import PropTypes from 'prop-types'

import { SelectInput } from '../../../common/inputs/select-input-styled'
import { Checkbox } from '../../../common/inputs/checkbox/checkbox-component'
import * as S from './recipe-filters-styled'

const RecipeFilters = ({
  countries,
  selectedCountry,
  handleSelectCountry,

  sortFields,
  selectedSortField,
  handleSelectSortField,

  tags,
  selectedTags,
  handleSelectTag,
}) => (
  <S.Grid>

    <S.CountryArea>
      <S.Text>Show recipes from:</S.Text>
      <SelectInput
        value={selectedCountry}
        onChange={e => handleSelectCountry(e.target.value)}
      >
        <option value="">Everywhere</option>
        {countries.map(country => (
          <option key={country.value} value={country.value}>
            {country.name}
          </option>
        ))}
      </SelectInput>
    </S.CountryArea>

    <S.SortArea>
      <S.Text>Sort by:</S.Text>
      <SelectInput
        value={selectedSortField}
        onChange={e => handleSelectSortField(e.target.value)}
      >
        {sortFields.map(field => (
          <option key={field} value={field}>
            {field}
          </option>
        ))}
      </SelectInput>
    </S.SortArea>

    <S.TagsArea>
      <S.Text>Filter recipes by tags:</S.Text>
      <S.TagList>
        {tags.map(tag => (
          <Checkbox
            key={tag.id}
            label={tag.Name}
            isChecked={selectedTags.includes(tag.Name)}
            handleClick={() => handleSelectTag(tag.Name)}
          />
        ))}
      </S.TagList>
    </S.TagsArea>

  </S.Grid>
)

RecipeFilters.propTypes = {
  sortFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSortField: PropTypes.string.isRequired,
  handleSelectSortField: PropTypes.func.isRequired,

  countries: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  selectedCountry: PropTypes.string.isRequired,
  handleSelectCountry: PropTypes.func.isRequired,

  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
  })).isRequired,
  selectedTags: PropTypes.array.isRequired,
  handleSelectTag: PropTypes.func.isRequired,
}

export { RecipeFilters }