import Moment from 'moment'

import { sortFields } from '../../../constants/sort-fields'

export const filterCountry = (countryCode, recipeArr) => (
  recipeArr.filter(recipe => recipe.countries.some(item => item.Alpha2Code === countryCode))
)

export const filterTags = (tags, recipeArr) => (
  recipeArr.filter(recipe => {
    const recipeTags = recipe.tags.map(tag => tag.Name);
    return tags.every(tagName => recipeTags.includes(tagName));
  })
)

const createSortRecipesByNameComparer = (shouldSortAscending) => (recipeA, recipeB) => {
  const titleA = recipeA.title.toUpperCase();
  const titleB = recipeB.title.toUpperCase();

  if (titleA > titleB) {

    return shouldSortAscending ? 1 : -1;

  } else if (titleA < titleB) {

    return shouldSortAscending ? -1 : 1;
  }

  return 0;
}

const sortRecipesByNameAscending = createSortRecipesByNameComparer(true);

const sortRecipesByNameDescending = createSortRecipesByNameComparer(false);

const createSortRecipesByDateComparer = (shouldSortAscending) => (recipeA, recipeB) => {
  const momentA = Moment(recipeA.date);
  const momentB = Moment(recipeB.date);

  if (momentA.isAfter(momentB)) {

    return shouldSortAscending ? 1 : -1;

  } else if (momentA.isBefore(momentB)) {

    return shouldSortAscending ? -1 : 1;
  }

  return 0;
}

const sortRecipesByDateAscending = createSortRecipesByDateComparer(true);

const sortRecipesByDateDescending = createSortRecipesByDateComparer(false);

export const sortRecipes = (sortField, recipeArr) => {
  const newRecipeArr = [...recipeArr];

  switch (sortField) {
    case sortFields.AZ:
      newRecipeArr.sort(sortRecipesByNameAscending);
      break;
    case sortFields.ZA:
      newRecipeArr.sort(sortRecipesByNameDescending);
      break;
    case sortFields.OLD:
      newRecipeArr.sort(sortRecipesByDateAscending);
      break;
    case sortFields.NEW:
    default:
      newRecipeArr.sort(sortRecipesByDateDescending);
      break;
  }

  return newRecipeArr;
}