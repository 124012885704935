const sortFields = {}

sortFields.NEW = 'New';

sortFields.OLD = 'Old';

sortFields.AZ = 'A-Z';

sortFields.ZA = 'Z-A';

export { sortFields }