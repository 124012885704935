import { useState, useEffect } from 'react'
import * as RecipeListHelpers from '../recipe-list-helpers'

export const useRecipeFilters = ({ recipes, sortFields }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedSortField, setSelectedSortField] = useState(sortFields[0]);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleSelectTag = (tagName) => {
    const index = selectedTags.indexOf(tagName);
    if (index < 0) {
      setSelectedTags([
        ...selectedTags,
        tagName,
      ])
    } else {
      setSelectedTags([
        ...selectedTags.slice(0, index),
        ...selectedTags.slice(index + 1),
      ])
    }
  }

  const handleSelectCountry = setSelectedCountry;

  const handleSelectSortField = setSelectedSortField;

  const [filteredRecipes, setFilteredRecipes] = useState(recipes);

  useEffect(() => {
    let tempRecipes = [...recipes];

    if (selectedCountry) {
      tempRecipes = RecipeListHelpers.filterCountry(selectedCountry, tempRecipes);
    }

    if (selectedTags.length > 0) {
      tempRecipes = RecipeListHelpers.filterTags(selectedTags, tempRecipes);
    }

    tempRecipes = RecipeListHelpers.sortRecipes(selectedSortField, tempRecipes);

    setFilteredRecipes(tempRecipes);
  }, [selectedCountry, selectedSortField, selectedTags, recipes])

  return {
    selectedTags,
    handleSelectTag,

    selectedCountry,
    handleSelectCountry,

    selectedSortField,
    handleSelectSortField,

    filteredRecipes,
  }
}