import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from 'react-fade-in'

import { RecipeSnippet } from '../../common/snippets/recipe-snippet/recipe-snippet-component'
import { List, ListItem } from '../../../components/common/snippets/snippet/snippet-styled'
import { RecipeFilters } from './recipe-filters/recipe-filters-component'
import { useRecipeFilters } from './hooks/use-recipe-filters-hook'
import { useRecipePagination } from './hooks/use-recipe-pagination-hook'
import * as S from './recipe-list-styled'
import { PaginationComponent } from './pagination/pagination-component'

const RecipeListComponent = ({ recipes, tags, sortFields, countries }) => {
  const {
    selectedCountry,
    handleSelectCountry,
    selectedSortField,
    handleSelectSortField,
    selectedTags,
    handleSelectTag,
    filteredRecipes,
  } = useRecipeFilters({ recipes, sortFields });

  const {
    currentPage,
    numberOfPages,
    pageNumbersToShow,
    shouldShowRecipe,
    handleChangePage,
  } = useRecipePagination({ recipesPerPage: 5, filteredRecipes });

  return (
    <FadeIn>
      <S.Header>

        <S.Title>Recipes</S.Title>

        <RecipeFilters
          countries={countries}
          selectedCountry={selectedCountry}
          handleSelectCountry={handleSelectCountry}

          sortFields={sortFields}
          selectedSortField={selectedSortField}
          handleSelectSortField={handleSelectSortField}

          tags={tags}
          selectedTags={selectedTags}
          handleSelectTag={handleSelectTag}
        />

      </S.Header>

      <PaginationComponent
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        pageNumbersToShow={pageNumbersToShow}
        handleChangePage={handleChangePage}
      />

      <List>
        <FadeIn>
          {filteredRecipes.length > 0 ? (

            filteredRecipes.map((recipe, i) =>

              shouldShowRecipe(i) ? (

                <ListItem key={recipe.id}>
                  <RecipeSnippet
                    title={recipe.title}
                    slug={recipe.slug}
                    countries={recipe.countries}
                    description={recipe.description}
                    image={recipe.image}
                  />
                </ListItem>

              ) : (
                null
              ))

          ) : (

            <S.EmptyListMessage>No recipes found matching the selected filters.</S.EmptyListMessage>

          )}
        </FadeIn>
      </List>

      <PaginationComponent
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        pageNumbersToShow={pageNumbersToShow}
        handleChangePage={handleChangePage}
      />

    </FadeIn>
  )
}

RecipeListComponent.propTypes = {
  recipes: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  sortFields: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
}

export { RecipeListComponent }
