import styled from 'styled-components'

import { media } from '../../../../constants/media'

export const Label = styled.label`
  font-family: ${props => props.theme.fonts.poppins};
  font-weight: 600;
  color: ${props => props.theme.colors.nori};
  line-height: 1.6rem;
  font-size: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  margin-right: 25px;
  margin-bottom: 28px;
  @media ${media.medium.query} {
    margin-right: 26px;
    margin-bottom: 23px;
  }

  transition: opacity 0.2s ease-in-out;
  opacity: ${props => props.isChecked ? '1' : '0.75'};
  :hover {
    opacity: 1;
  }
`;

export const Img = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 9px;
`;