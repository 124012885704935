import { useState, useEffect } from 'react'

export const useRecipePagination = ({ recipesPerPage, filteredRecipes }) => {
  // Manage Pagination
  const RECIPES_PER_PAGE = recipesPerPage;
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(Math.ceil(filteredRecipes.length / RECIPES_PER_PAGE));
  const [pageNumbersToShow, setPageNumbersToShow] = useState([1, 2, 3]);

  const handleChangePage = setCurrentPage;

  // Handle changing filters
  useEffect(() => {
    const newNumberOfPages = Math.ceil(filteredRecipes.length / RECIPES_PER_PAGE) || 1;
    if (newNumberOfPages !== numberOfPages) {
      setNumberOfPages(newNumberOfPages);
      setCurrentPage(1);
    }
  }, [filteredRecipes, RECIPES_PER_PAGE, numberOfPages])

  // Update page numbers to show
  useEffect(() => {
    const newPageNumbersToShow = [];
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;

    if (prevPage > 0) {
      newPageNumbersToShow.push(prevPage);
    }

    newPageNumbersToShow.push(currentPage);

    if (nextPage <= numberOfPages) {
      newPageNumbersToShow.push(nextPage);
    }

    if (newPageNumbersToShow.length < 3 && numberOfPages >= 3) {
      if (currentPage === 1) {
        newPageNumbersToShow.push(nextPage + 1);
      } else {
        newPageNumbersToShow.unshift(prevPage - 1);
      }
    }

    setPageNumbersToShow(newPageNumbersToShow);

  }, [currentPage, numberOfPages])

  const shouldShowRecipe = (index) => {
    const maxIndex = RECIPES_PER_PAGE * currentPage;
    const minIndex = maxIndex - RECIPES_PER_PAGE + 1;

    const nonZeroIndex = index + 1;

    return nonZeroIndex >= minIndex && nonZeroIndex <= maxIndex;
  }

  return {
    currentPage,
    numberOfPages,
    pageNumbersToShow,
    shouldShowRecipe,
    handleChangePage,
  }
}