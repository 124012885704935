import React from 'react'
import PropTypes from 'prop-types'

import * as S from './pagination-styled'

const PaginationComponent = ({
  currentPage,
  numberOfPages,
  pageNumbersToShow,
  handleChangePage,
}) => (
  numberOfPages > 1 ? (
    <S.PaginationContainer>

      <S.NumberOfPagesSection>
        Page {currentPage} of {numberOfPages}
      </S.NumberOfPagesSection>

      <S.PageLinksSection>

        <S.BoundaryPageLink
          onClick={() => handleChangePage(1)}
          isDisabled={currentPage === 1}
        >
          First
        </S.BoundaryPageLink>

        <S.BoundaryPageLink
          onClick={() => currentPage !== 1 && handleChangePage(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          <S.LeftArrowIcon />
        </S.BoundaryPageLink>

        {pageNumbersToShow.map(num =>
          num === currentPage ? (
            <S.SelectedPage key={num}>{num}</S.SelectedPage>
          ) : (
            <S.PageLink
              key={num}
              onClick={() => handleChangePage(num)}
            >
              {num}
            </S.PageLink>
          )
        )}

        <S.BoundaryPageLink
          onClick={() => currentPage !== numberOfPages && handleChangePage(currentPage + 1)}
          isDisabled={currentPage === numberOfPages}
        >
          <S.RightArrowIcon />
        </S.BoundaryPageLink>

        <S.BoundaryPageLink
          onClick={() => handleChangePage(numberOfPages)}
          isDisabled={currentPage === numberOfPages}
        >
          Last
        </S.BoundaryPageLink>

      </S.PageLinksSection>

    </S.PaginationContainer>
  ) : (
    <>
    </>
  )
)

PaginationComponent.propTypes = {
  numberOfPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageNumbersToShow: PropTypes.array.isRequired,
  handleChangePage: PropTypes.func.isRequired,
}

export { PaginationComponent }