import React from 'react'
import PropTypes from 'prop-types'

import { convertCountriesToString } from '../../../../helpers/country/country'
import { createRecipeUrl } from '../../../../helpers/url/url'
import { Snippet } from '../snippet/snippet-component'

const RecipeSnippet = ({
  title,
  slug,
  countries,
  description,
  image,
}) => (
  <Snippet
    image={image}
    title={title}
    subtitle={convertCountriesToString(countries)}
    content={description}
    url={createRecipeUrl(slug)}
  />
)

RecipeSnippet.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export { RecipeSnippet }