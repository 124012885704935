import styled from 'styled-components'

import { media } from '../../../../constants/media'

export const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 100px;
  grid-template-areas:
    "country"
    "sort"
    "tags";
  margin: 0 20px;
  @media ${media.medium.query} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px;
    grid-template-areas:
      "country sort"
      "tags tags";
    margin: 0;
  }
`;

export const Text = styled.div`
  text-align: left;
  font-family: ${props => props.theme.fonts.poppins};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.012rem;
  color: ${props => props.theme.colors.salmon};
  margin-bottom: 20px;
`;

export const CountryArea = styled.div`
  grid-area: country;
`;

export const SortArea = styled.div`
  grid-area: sort;
  margin-bottom: 40px;
  @media ${media.medium.query} {
    margin: 0;
  }
`;

export const TagsArea = styled.div`
  grid-area: tags;
`;

export const TagList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;