import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { sortFields } from '../../../constants/sort-fields'
import { RecipeListComponent } from './recipe-list-component'
import { getCountryName } from '../../../helpers/country/country'

const RecipeList = props => (
  <StaticQuery
    query={recipeListQuery}
    render={data => {

      const countryOptions = data.allStrapiCountry.edges.map(edge => {
        const countryObj = edge.node;
        const name = getCountryName(countryObj);
        const { Alpha2Code } = countryObj;

        return {
          name: name,
          value: Alpha2Code
        };
      })
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
      });

      return (
        <RecipeListComponent
          recipes={data.allStrapiRecipe.edges.map(item => item.node)}
          tags={data.allStrapiTag.edges.map(item => item.node)}
          sortFields={Object.values(sortFields)}
          countries={countryOptions}
          {...props}
        />
      )
    }}
  />
)

const recipeListQuery = graphql`
  query RecipeListQuery {

    allStrapiRecipe (
      sort: {
        fields: [date]
        order: DESC
      }
    ) {
      edges {
        node {
          id
          title
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          date
          countries {
            Alpha2Code
            ShortName
            Name
          }
          description
          tags {
            id
            Name
          }
        }
      }
    }

    allStrapiTag {
      edges {
        node {
          id
          Name
        }
      }
    }

    allStrapiCountry (filter: {
      recipes: {
        elemMatch: {
          id: { ne: null }
        }
      }
    }) {
      edges {
        node {
          Alpha2Code
          Name
          ShortName
          recipes {
            id
          }
        }
      }
    }

  }
`;

export { RecipeList }