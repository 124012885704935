import styled from 'styled-components'

import { media } from '../../../../constants/media'

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 0;
`;

export const LinkWrapper = styled.a`
  display: block;
  text-decoration: none;
  margin: 15px 0;
  width: 100%;
  max-width: ${media.small.maxWidth};
  @media ${media.medium.query} {
    max-width: ${media.medium.maxWidth};
  }
`;

export const Grid = styled.div`
  background-color: ${props => props.theme.colors.white};
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(1fr, ${media.small.maxWidth});
  grid-template-rows: 210px 230px;
  grid-template-areas:
    "image"
    "info";
  @media ${media.medium.query} {
    grid-template-columns: ${media.medium.snippetColumns};
    grid-template-rows: 230px;
    grid-template-areas:
      "image info";
    border-radius: 17px;

    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    :hover {
      box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }
  }
`;

export const ImageArea = styled.div`
  grid-area: image;
  overflow: hidden;
  & > div {
    height: 100%;
  }
`;

export const InfoArea = styled.div`
  grid-area: info;
  margin: 25px 30px;
  overflow: hidden;
  @media ${media.medium.query} {
    text-align: left;
    margin: 30px 40px;
  }
`;

export const CardTitle = styled.h4`
  font-family: ${props => props.theme.fonts.solway};
  color: ${props => props.theme.colors.salmon};
  font-weight: normal;
  font-size: 2.8rem;
  line-height: 3.1rem;
  @media ${media.medium.query} {
    font-size: 3.3rem;
    line-height: 4.2rem;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardSubtitle = styled.h5`
  font-family: ${props => props.theme.fonts.solway};
  color: ${props => props.theme.colors.nori};
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.01rem;
  margin-bottom: 15px;
  @media ${media.medium.query} {
    font-size: 1.4rem;
    line-height: 1.6rem;
    letter-spacing: 0.011rem;
    margin-bottom: 20px;
  }
`;

export const CardBody = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.nori};
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.6rem;
  letter-spacing: 0;
  @media ${media.medium.query} {
    line-height: 2.4rem;
  }

  display: block;
  display: -webkit-box;
  line-clamp: 4;
  box-orient: vertical;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;