import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import * as S from './snippet-styled'

const Snippet = ({
  image,
  title,
  subtitle,
  content,
  url,
}) => (
  <S.LinkWrapper href={url} alt={title}>
    <S.Grid>

      <S.ImageArea>
        <Img
          fluid={image.childImageSharp.fluid}
          alt={title}
        />
      </S.ImageArea>

      <S.InfoArea>

        <S.CardTitle>{title}</S.CardTitle>

        <S.CardSubtitle>{subtitle}</S.CardSubtitle>

        <S.CardBody>{content}</S.CardBody>

      </S.InfoArea>

    </S.Grid>
  </S.LinkWrapper>
)

Snippet.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export { Snippet }