import styled from 'styled-components'

import { media } from '../../../constants/media'

export const Header = styled.div`
  margin: 0 20px;
  margin-bottom: 30px;
  @media ${media.medium.query} {
    margin: 0 50px;
    margin-bottom: 60px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-family: ${props => props.theme.fonts.solway};
  font-weight: 800;
  font-size: 4rem;
  margin-bottom: 5rem;
  line-height: 0.105rem;
  letter-spacing: 0.048rem;
  color: ${props => props.theme.colors.nori};
  @media ${media.medium.query} {
    text-align: left;
    font-size: 6rem;
    line-height: 6.1rem;
    letter-spacing: 0.07rem;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-family: ${props => props.theme.fonts.solway};
  font-size: 2rem;
  color: ${props => props.theme.colors.nori};

  margin: 0;
  margin-top: 30px;
  @media ${media.medium.query} {
    margin: 0 50px;
    margin-top: 30px;
  }
`;

export const NumberOfPagesSection = styled.div`
`;

export const PageLinksSection = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & * {
    margin: 0 10px;
  }
`;

export const PageLink = styled.a`
  color: ${props => props.theme.colors.salmon};
  text-decoration: underline;
  cursor: pointer;
  padding: 2px 8px;
  user-select: none;
  border: 3px solid transparent;
  border-radius: 10px;
  transition: border 0.2s linear;
  :hover {
    border: 3px solid ${props => props.theme.colors.salmon};
  }
`;

export const SelectedPage = styled.div`
  color: ${props => props.theme.colors.salmon};
  font-weight: bold;
  padding: 2px 8px;
  user-select: none;
  border: 3px solid ${props => props.theme.colors.salmon};
  border-radius: 10px;
`;

export const BoundaryPageLink = styled.a`
  color: ${props => props.isDisabled ? props.theme.colors.gray : props.theme.colors.salmon};
  text-decoration: ${props => props.isDisabled ? 'none' : 'underline'};
  cursor: ${props => props.isDisabled ? 'normal' : 'pointer'};
  padding: 2px 8px;
  user-select: none;
`;

export const EmptyListMessage = styled.div`
  font-size: 2rem;
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.pacific};
  margin: 3rem;
`;