import React from 'react'
import PropTypes from 'prop-types'

import checkedImg from './assets/checkbox_on.svg'
import uncheckedImg from './assets/checkbox_off.svg'
import * as S from './checkbox-styled'

const Checkbox = ({
  label,
  isChecked,
  handleClick,
}) => (
  <S.Label isChecked={isChecked} onClick={handleClick}>

    <S.Img
      src={isChecked ? checkedImg : uncheckedImg}
      alt="checkbox"
    />

    {label}

  </S.Label>
)

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
}

export { Checkbox }