import React from 'react'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { RecipeList } from '../components/pages/recipe-list/recipe-list-container'
import { EmailSubscribeForm } from '../components/common/email-subscribe-form/email-subscribe-form-container'

const RecipeListPage = () => (
  <Layout>

    <SEO
      title="Recipes"
      keyword={['recipes', 'recipe', 'cooking', 'travel', 'travelling']}
    />

    <RecipeList />

    <EmailSubscribeForm showLabel />

  </Layout>
)

export default RecipeListPage;